@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");

.app {
  background-color: #4286f4;
}

.paper {
  width: 300px;
  height: 150px;
  border-radius: 50%;
  color: #1b1370;
}
